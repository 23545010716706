var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { State, Getter, namespace } from "vuex-class";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import DataTree from "~/components/common/data-tree.vue";
import { OrganizationService } from "~/services/management-service/organization.service";
import AddOrganize from "~/components/system-manage/user-manage/add-organize.vue";
import ModifyOrganize from "~/components/system-manage/user-manage/modify-organize.vue";
import PersonRoster from "~/components/assist-work/person-roster.vue";
import PersonUploadFile from "~/components/assist-work/person-upload-file.vue";
import PersonDetail from "~/components/assist-work/person-detail.vue";
// 在职
import OnJob from "~/components/assist-work/on-job.vue";
// 离职
import LeaveOffice from "~/components/assist-work/leave-office.vue";
// 数据导入
import ImportRoster from "~/components/assist-work/import-roster.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { UserExportService } from "~/services/dataimp-service/user-export.service";
var systemManageModule = namespace("system-manage");
var organizationManage = /** @class */ (function (_super) {
    __extends(organizationManage, _super);
    function organizationManage() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.activeName = "pending-info";
        _this.dataList = [];
        _this.dept = "";
        _this.deptId = "";
        _this.editdata = {};
        _this.modifyData = {};
        _this.deptIds = [];
        _this.userId = "";
        _this.operatorUpdateModelList = [];
        _this.userModel = {
            organizationId: "",
            fullName: "",
            userName: "",
            phone: "",
            callPhone: "",
            idCard: "",
            birthday: [],
            political: "",
            experience: "",
            education: "",
            major: "",
            station: "",
            workUnit: "",
            isShowAll: "1",
            resignationDate: [],
            createTime: [],
            signDate: [],
            endDate: []
        };
        _this.deleteModel = {
            idList: ""
        };
        _this.operatorUpdateModel = {
            userIds: "",
            departId: ""
        };
        _this.exportModel = {
            ids: []
        };
        _this.dialog = {
            modify: false,
            personRoster: false,
            editOrganizeFlag: false,
            addOrganizeFlag: false,
            upload: false,
            detail: false,
            import: false,
            group: false
        };
        return _this;
    }
    //刷新数据
    organizationManage.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    organizationManage.prototype.onChange = function (value) {
        this.userModel.organizationId = value.id;
        this.deptId = value.id;
        this.dept = value;
        this.refreshData();
    };
    organizationManage.prototype.onAdd = function (value) {
        this.dialog.addOrganizeFlag = true;
    };
    organizationManage.prototype.onEdit = function (value) {
        var _this = this;
        this.dialog.editOrganizeFlag = true;
        this.editdata = value;
        var parentData = this.orgInfo.find(function (v) { return v.id === _this.editdata.parent; }) || {};
        Object.assign(this.editdata, {
            parentName: parentData.name,
            parentLevel: parentData.level
        });
    };
    organizationManage.prototype.onDelete = function (value) {
        var _this = this;
        this.$confirm("此操作将永久删除该组织机构, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.organizationService.deleteOrganization(value.id).subscribe(function (data) {
                _this.updateOrgNameInfo(_this.userData.organization);
                _this.$message.success("删除成功!");
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () {
            _this.$message.error("已取消删除");
        });
    };
    organizationManage.prototype.dialogOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var addUser = _this.$refs["add-dept"];
            addUser.refresh(_this.dept);
        });
    };
    /**
     * 导入人员花名册
     */
    organizationManage.prototype.dataImpClick = function () {
        this.dialog.import = true;
    };
    /**
     * 修改机构open事件(获取机构)
     */
    organizationManage.prototype.getOragnize = function () {
        var _this = this;
        this.$nextTick(function () {
            var edit = _this.$refs["edit"];
            edit.refresh();
        });
        document.documentElement.style.overflow = "hidden";
    };
    organizationManage.prototype.updataOrganizationTree = function () {
        this.updateOrgNameInfo(this.userData.organization);
    };
    organizationManage.prototype.viewDetails = function (row) {
        this.modifyData = row;
        this.dialog.detail = true;
    };
    organizationManage.prototype.modify = function (row) {
        this.modifyData = row;
        this.dialog.personRoster = true;
    };
    organizationManage.prototype.upload = function (id) {
        this.userId = id;
        this.dialog.upload = true;
    };
    organizationManage.prototype.personRosterClose = function () {
        this.modifyData = {};
        var personRoster = this.$refs["person-roster"];
        personRoster.reset();
    };
    //切换tab页面刷新对应的页面
    organizationManage.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.refreshData();
    };
    organizationManage.prototype.mounted = function () {
        this.updateOrgNameInfo(this.userData.organization);
    };
    /**
     * 下载导入人员花名册
     */
    organizationManage.prototype.downLoadImpClick = function () {
        // this.systemParamService
        //   .downLoadImport({ code: "operator.import.template" })
        //   .subscribe(
        //     data => {
        //       if (data) {
        //         this.uploadFileService.getFileStreamById(data.fileId);
        //       }
        //     },
        //     ({ msg }) => { }
        //   );
    };
    /***
     * 批量删除用户
     */
    organizationManage.prototype.deletedOperator = function (data) {
        var handleInfo = this.$refs["handle-info"];
        if (handleInfo.selectionList.length === 0) {
            this.$message("请选择要删除的用户");
            return;
        }
        this.deleteModel.idList = handleInfo.selectionList.map(function (x) { return x.id; });
        //  this.userRegisterService.deleteOperator(this.deleteModel).subscribe(
        //    data => {
        //      this.$message.success("操作成功");
        //      handleInfo.refreshData();
        //    },
        //    err => this.$message.error(err.msg)
        //  )
    };
    /**
     * 导出人员花名册
     */
    organizationManage.prototype.dataExportClick = function () {
        var _this = this;
        var userInformation = this.$refs[this.activeName];
        if (userInformation.selectionList.length > 0) {
            this.exportModel.ids = userInformation.selectionList.map(function (x) { return x.id; });
            this.loading.state = true;
            this.userExportService
                .exportUserList(this.exportModel, this.loading)
                .subscribe(function (data) {
                _this.$message.success("导出成功"), userInformation.refreshData();
            }, function (err) { return _this.$message.error(err.message); });
        }
        if (userInformation.selectionList.length === 0) {
            this.exportModel.status =
                this.activeName === "pending-info" ? "INCUMBENCY" : "DIMISSION";
            this.$confirm("您未选择要导出的用户，请确认是否导出所有用户？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                _this.exportModel.ids = [];
                _this.loading.state = true;
                _this.userExportService
                    .exportUserList(_this.exportModel, _this.loading)
                    .subscribe(function (data) {
                    _this.$message.success("导出成功"), userInformation.refreshData();
                }, function (err) { return _this.$message.error(err.message); });
            });
        }
    };
    organizationManage.prototype.moveGroup = function () {
        var userInformation = this.$refs["pending-info"];
        if (userInformation.selectionList.length === 0) {
            this.$message("请选择要移动到组的用户");
            return;
        }
        this.dialog.group = true;
        this.operatorUpdateModel.userIds = userInformation.selectionList.map(function (x) { return x.id; });
    };
    organizationManage.prototype.close = function () {
        this.dialog.group = false;
    };
    organizationManage.prototype.submit = function () {
        // let userInformation: any = this.$refs["pending-info"];
        // this.userRegisterService.updateUserOrganization(this.operatorUpdateModel)
        //       .subscribe(
        //         data => {
        //           this.$message.success("操作成功"),
        //           userInformation.refreshData();
        //           this.close();
        //           this.reset();
        //         },
        //   err => this.$message.error(err.message));
    };
    /**
     * 机构改变
     */
    organizationManage.prototype.orgChange = function (valueArray) {
        if (this.deptIds.length === 0) {
            return;
        }
        this.operatorUpdateModel.departId = valueArray[valueArray.length - 1];
    };
    organizationManage.prototype.reset = function () {
        delete this.exportModel.idList;
        delete this.exportModel.status;
        delete this.operatorUpdateModel.userIds;
        delete this.operatorUpdateModel.departId;
    };
    __decorate([
        Dependencies(PageService)
    ], organizationManage.prototype, "pageService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], organizationManage.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(OrganizationService)
    ], organizationManage.prototype, "organizationService", void 0);
    __decorate([
        Dependencies(UserExportService)
    ], organizationManage.prototype, "userExportService", void 0);
    __decorate([
        Getter
    ], organizationManage.prototype, "departmentData", void 0);
    __decorate([
        systemManageModule.State
    ], organizationManage.prototype, "orgInfo", void 0);
    __decorate([
        Getter
    ], organizationManage.prototype, "orgTreeData", void 0);
    __decorate([
        systemManageModule.Action
    ], organizationManage.prototype, "updateOrgNameInfo", void 0);
    __decorate([
        State
    ], organizationManage.prototype, "userData", void 0);
    organizationManage = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataTree: DataTree,
                DataForm: DataForm,
                DataBox: DataBox,
                AddOrganize: AddOrganize,
                ModifyOrganize: ModifyOrganize,
                PersonRoster: PersonRoster,
                PersonUploadFile: PersonUploadFile,
                PersonDetail: PersonDetail,
                OnJob: OnJob,
                LeaveOffice: LeaveOffice,
                ImportRoster: ImportRoster
            }
        })
    ], organizationManage);
    return organizationManage;
}(Vue));
export default organizationManage;
