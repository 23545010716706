var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { DataGrid, DataGridItem } from "@zct1989/vue-component";
import { Prop } from "vue-property-decorator";
import { State } from "vuex-class";
import DataBox from "~/components/common/data-box.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { UserRegisterService } from "~/services/management-service/user-register.service";
var PersonDetail = /** @class */ (function (_super) {
    __extends(PersonDetail, _super);
    function PersonDetail() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PersonDetail.prototype, "FileLists", {
        get: function () {
            return this.rowDetail.fileContent || [];
        },
        enumerable: false,
        configurable: true
    });
    PersonDetail.prototype.getDepartment = function (organizationId) {
        var department = this.departmentList.find(function (v) { return v.id === organizationId; });
        if (!department)
            return "";
        return department.name;
    };
    PersonDetail.prototype.downloadFile = function (row) {
        var _this = this;
        this.uploadFileService
            .getFileStreamById(row.fileId, row.fileName)
            .subscribe(function () { return _this.$message.success("下载成功"); });
    };
    PersonDetail.prototype.deleteFile = function (row) {
        var fileId = row.fileId;
        // this.userRegisterService.deleteFileId(this.rowDetail.id,fileId).subscribe(
        //   data => {
        //     this.$message.success("操作成功")
        //     // TODO:不推荐的方法，可靠的方式是传入用户ID，然后根据ID获取同的模块数据
        //     // 操作完成之后重新获取一次即可
        //     let index = this.FileLists.findIndex(v => v.fileId === fileId)
        //     if(index > -1){
        //       this.FileLists.splice(index ,1)
        //     }
        //   },
        //   err => this.$message.error(err.msg)
        // )
    };
    __decorate([
        Dependencies(UploadFileService)
    ], PersonDetail.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(UserRegisterService)
    ], PersonDetail.prototype, "userRegisterService", void 0);
    __decorate([
        State
    ], PersonDetail.prototype, "departmentList", void 0);
    __decorate([
        Prop({ required: true })
    ], PersonDetail.prototype, "rowDetail", void 0);
    PersonDetail = __decorate([
        Component({
            components: {
                DataGrid: DataGrid,
                DataGridItem: DataGridItem,
                DataBox: DataBox
            }
        })
    ], PersonDetail);
    return PersonDetail;
}(Vue));
export default PersonDetail;
